<template>
  <div class="pa-12 mb-8" :style="{ backgroundColor: '#f5f5f5' }">
    <v-text-field
      v-model="lat"
      label="撮影場所の緯度"
      placeholder="35.681236"
      class="my-4"
      type="text"
      required
      outlined
      persistent-hint
      background-color="white"
      :error-messages="errorLat"
      hint="※ 小数点以下7桁以降は切り捨てられます。"
    />

    <v-text-field
      v-model="lng"
      label="撮影場所の経度"
      placeholder="139.767124"
      class="my-4"
      type="text"
      required
      outlined
      background-color="white"
      hint="※ 小数点以下7桁以降は切り捨てられます。"
      :error-messages="errorLng"
      persistent-hint
    />

    <v-radio-group v-model="selectedMap" row>
      <v-radio label="OpenStreetMap" value="osm" />
      <v-radio
        label="Google Maps"
        value="gmap"
        @click="isDisplayedDialog = true"
      />
    </v-radio-group>

    <form class="d-flex mb-2">
      <v-text-field
        v-model="address"
        label="撮影場所を検索"
        placehoder="地名、施設名で検索できます"
        type="text"
        outlined
        clearable
        background-color="white"
        hint="※ デフォルトはOpenStreetMapの場所検索です。地図をGoogle Mapsに切り替えるとGoogle Mapsの場所検索になります。"
        persistent-hint
      />

      <v-btn
        depressed
        height="56px"
        color="primary"
        @click="
          () => {
            selectedMap === 'osm' ? geocodeOsm() : geocodeGoogleMaps();
          }
        "
      >
        検索
      </v-btn>
    </form>

    <div
      v-show="selectedMap === 'osm'"
      id="leaflet"
      :style="{ height: '360px' }"
    />

    <div
      v-show="selectedMap === 'gmap'"
      id="gmap"
      :style="{ height: '360px' }"
      @click="isDisplayedDialog = true"
    >
      <GmapMap
        v-if="currentLocation || geo"
        :center="currentLocation || geo"
        :zoom="12"
        :options="{
          draggableCursor: 'default',
        }"
        :style="{ width: '100%', height: '100%' }"
        @click="setLocationGoogleMaps"
      >
        <GmapMarker
          v-if="currentLocation"
          icon="https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
          :position="currentLocation"
        />
        <GmapMarker
          v-if="(lat && lng) || geo"
          icon="https://maps.google.com/mapfiles/ms/icons/red-dot.png"
          :position="geo"
          :draggable="true"
          @dragend="setLocationGoogleMaps($event)"
        />
      </GmapMap>
    </div>

    <v-row justify="end" class="pr-3 mt-4">
      <v-btn large color="primary" @click="goCurrent">現在地へ</v-btn>
    </v-row>

    <v-dialog
      v-model="isDisplayedDialogIncludesNextTime"
      max-width="400"
      :style="{ zIndex: 1000 }"
    >
      <v-card class="py-12 px-10">
        <v-card-title>
          Google Mapsで取得した緯度経度はオープンデータにはできません
        </v-card-title>
        <v-card-text>
          オープンデータとして公開する緯度経度を取得するときは、OpenStreetMapをご利用ください。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isDisplayedDialog = false">
            閉じる
          </v-btn>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small @click="setAcceptGoogleMapsTerms">
            このメッセージを二度と表示しない
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as vueGoogleMaps from "vue2-google-maps";
import "leaflet/dist/leaflet.css";
import leaflet from "leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";

delete leaflet.Icon.Default.prototype._getIconUrl;
leaflet.Icon.Default.mergeOptions({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "VideoNewFormLocation",
  props: {
    latitude: {
      type: Number,
      default: null,
    },
    longitude: {
      type: Number,
      default: null,
    },
    errorLat: String,
    errorLng: String,
  },

  data() {
    return {
      selectedMap: "osm",
      address: "",
      currentLocation: null,
      initialCoordinates: {
        // 初期座標：東京駅
        latitude: 35.681236,
        longitude: 139.767124,
      },
      osmMap: null,
      osmMarker: null,
      isUsedGoogleMaps: false,
      isDisplayedDialog: false,
    };
  },
  computed: {
    lat: {
      get: function () {
        return this.latitude;
      },
      set: function (value) {
        let valueNumber = value;
        if (typeof value === "string") {
          valueNumber = Number(value);
        }
        this.$emit("save-lat", this.round(valueNumber));
      },
    },
    lng: {
      get: function () {
        return this.longitude;
      },
      set: function (value) {
        let valueNumber = value;
        if (typeof value === "string") {
          valueNumber = Number(value);
        }
        this.$emit("save-lng", this.round(valueNumber));
      },
    },
    geo: {
      get: function () {
        if (this.lat && this.lng) {
          return {
            lat: this.lat,
            lng: this.lng,
          };
        }
        return {
          lat: this.initialCoordinates.latitude,
          lng: this.initialCoordinates.longitude,
        };
      },
    },
    geoForOsm: {
      get: function () {
        if (this.currentLocation) {
          return [this.currentLocation.lat, this.currentLocation.lng];
        } else if (this.lat && this.lng) {
          return [this.lat, this.lng];
        }
        return [this.initialCoordinates.latitude, this.initialCoordinates.longitude];
      },
    },
    isDisplayedDialogIncludesNextTime: {
      get: function () {
        return (
          this.isDisplayedDialog &&
          window.localStorage.getItem("accept_google_maps_terms") !== "true"
        );
      },
    },
  },
  mounted() {
    this.osmMap = leaflet.map("leaflet").setView(this.geoForOsm, 12);
    this.osmMarker = leaflet
      .marker(this.geoForOsm, { draggable: true })
      .addTo(this.osmMap);
    this.osmMap.getPanes().mapPane.style.zIndex = 1;
    const osm = leaflet.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <A href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    });
    osm.addTo(this.osmMap);

    this.osmMap.on("click", (e) => {
      this.isUsedGoogleMaps = false;
      this.osmMarker.setLatLng(e.latlng);

      this.setLocationOsm(e.latlng.lat, e.latlng.lng);
    });

    this.osmMarker.on("dragend", (e) => {
      this.isUsedGoogleMaps = false;
      this.setLocationOsm(e.target._latlng.lat, e.target._latlng.lng);
    });
  },
  methods: {
    round(num) {
      return Math.floor(num * 1000000) / 1000000;
    },
    async goCurrent() {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      this.currentLocation = {
        lat: this.round(position.coords.latitude),
        lng: this.round(position.coords.longitude),
      };

      this.$emit("use-osm");
      this.osmMarker.setLatLng(this.currentLocation);
      this.osmMap.setView(this.currentLocation, 12);
    },
    setLocationGoogleMaps(e) {
      this.$emit("use-google-maps");

      this.lat = e.latLng.lat();
      this.lng = e.latLng.lng();
    },
    setLocationOsm(lat, lng) {
      this.$emit("use-osm");

      this.lat = lat;
      this.lng = lng;
    },
    geocodeGoogleMaps() {
      this.$emit("use-google-maps");

      const maps = vueGoogleMaps.gmapApi().maps;
      const geocoder = maps.Geocoder.prototype;
      geocoder.geocode({ address: this.address }, (results, status) => {
        if (status !== maps.GeocoderStatus.OK || results.length === 0) {
          return;
        }
        this.lat = results[0].geometry.location.lat();
        this.lng = results[0].geometry.location.lng();
      });
    },
    geocodeOsm() {
      this.$emit("use-osm");

      const provider = new OpenStreetMapProvider();
      provider
        .search({ query: this.address })
        .catch((e) => {
          console.log(e);
          return e;
        })
        .then((results) => {
          this.lat = results[0].y;
          this.lng = results[0].x;
        })
        .then(() => {
          // thenを分けないと、this.lat, this.lng が書き変わってくれない
          this.osmMarker.setLatLng([this.lat, this.lng]);
          this.osmMap.setView([this.lat, this.lng], 12);
        });
    },
    setAcceptGoogleMapsTerms() {
      window.localStorage.setItem("accept_google_maps_terms", "true");
    },
  },
};
</script>
